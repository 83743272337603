/* eslint-disable i18next/no-literal-string */
import React from 'react';
import ReactDOM from 'react-dom';
import DeviceLibrary3 from './components/DeviceLibrary3';
import FamilyLibrary from './components/FamilyLibrary';
import DeviceConfig from './components/DeviceConfig';
import { allowSso } from './utils/AuthService';
import { Router, Route, browserHistory } from 'react-router';
import Callback from './components/Callback';
import Home from './components/Home';
import MobileHome from './components/MobileHome';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './i18n';
import DeviceBetaConfig from './components/DeviceBetaConfig';
import DeviceBetaRhbConfig from './components/DeviceBetaRhbConfig';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#fff',
            main: '#F95E3F',
            dark: '#dc492e'
        },
        secondary: {
            main: '#003c1f',
        },
    },
    typography: {
        useNextVariants: true
    }
});

const Root = () => {
    return (
        <div className="content">
            <Router history={browserHistory}>
                <Route path="/" component={Home}  onEnter={allowSso} />
                <Route path="/mobile-home" component={MobileHome}  onEnter={allowSso} />
                <Route path="/yoto/:deviceId/beta" component={DeviceBetaConfig} onEnter={allowSso} />
                <Route path="/yoto/:deviceId/beta-rhb" component={DeviceBetaRhbConfig} onEnter={allowSso} />
                <Route path="/yoto/:deviceId/myo" component={DeviceLibrary3} onEnter={allowSso} />
                <Route path="/yoto/:deviceId/library" component={DeviceLibrary3} onEnter={allowSso} />
                <Route path="/yoto/:deviceId/library-beta" component={DeviceLibrary3} onEnter={allowSso} />
                <Route path="/yoto/:deviceId/library-v3" component={DeviceLibrary3} onEnter={allowSso} />
                <Route path="/library" component={FamilyLibrary} onEnter={allowSso} />
                <Route path="/yoto/:deviceId/config" component={DeviceConfig} onEnter={allowSso} />
                <Route path="/callback" component={Callback} />
                <Route path="/android/yoto/:deviceId/library" component={DeviceLibrary3} onEnter={allowSso} />
            </Router>
        </div>
    );
};

ReactDOM.render(
    <MuiThemeProvider theme = { theme }>
        <Root />
    </MuiThemeProvider>, document.getElementById('root'));
