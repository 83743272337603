/* eslint-disable i18next/no-literal-string */
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import lngDetector from 'i18next-browser-languagedetector';

import translation_en from './i18n/en/translation.json';
import translation_fr from './i18n/fr/translation.json';

const resources = {
    en: {
        translation: translation_en
    },
    fr: {
        translation: translation_fr
    }
};

i18n
    .use(lngDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: { order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'] },
        resources,
        fallbackLng: 'en',

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });
