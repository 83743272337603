import React, { Component } from 'react';

const MAIN_HOMEPAGE_URL = '/mobile-home';

class Home extends Component {


    componentDidMount() {
        window.location = MAIN_HOMEPAGE_URL;
    }

    render() {
        return(<div/>);
    }
}

export default Home;

