/* eslint-disable i18next/no-literal-string */
import React, { Component } from 'react';
import '../index.css';

class FamilyLibrary extends Component {


    render() {

        return (
            <div>
                To display your library you will need to update the Yoto app to the latest version found in the app store.
            </div>
        );
    }
}

export default FamilyLibrary;
