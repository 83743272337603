/* eslint-disable i18next/no-literal-string */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../index.css';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
//import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import HistoryIcon from '@material-ui/icons/History';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import ListItemText from '@material-ui/core/ListItemText/ListItemText';

import { withTranslation } from 'react-i18next';
import FormControl from "@material-ui/core/FormControl";
import {IconButton} from "@material-ui/core";

const api = require('../utils/yoto-api');

const PLAY_ENTIRE_CARD = "_PLAY_ENTIRE_CARD_";

const BUTTON_PLAY_LIB_CARD = {
    cardId: '3nC80',
    card: {
        cardId: '3nC80',
        title: 'Yoto Daily',
        content: {
            chapters: [
                {
                    key: 'daily',
                    title: 'Yoto Daily',
                    tracks: [
                        {
                            key: '<yyyymmdd>',
                            title: 'Yoto Daily',
                        }
                    ]
                },
                {
                    key: 'radio-day',
                    title: 'Yoto Radio',
                    tracks: [
                        {
                            key: '01',
                            title: 'Yoto Radio'
                        }
                    ]
                },
                {
                    key: 'radio-night',
                    title: 'Yoto Sleep Radio',
                    tracks: [
                        {
                            key: '01',
                            title: 'Yoto Sleep Radio'
                        }
                    ]
                }
            ]
        }

    }
}

class DeviceBetaRhbModeConfig extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: props.mode,
            config: props.config,
            cardItems: [],
            library: props.library,
            selectedCard: null,
            selectedChapter: null,
            selectedPosition: null,
            onChange: props.onChange
        };
    }

    async componentDidMount() {

        const config = this.state.config || { content: [] };
        const cardItems = config.content.filter(c => (c.cmd === 'track-play' || c.cmd === 'card-play'));
        const lastPlayed = config.content.find(c => (c.cmd === 'play-last'));
        const lastPlayedNum = lastPlayed ? parseInt(lastPlayed.params.num) : 0;
        this.setState({loading: true})
        if (cardItems) await this.hydrateCardIds(cardItems.map(c => c.params.card));
        this.setState({
            cardItems,
            lastPlayedNum,
            mode: this.props.mode,
            library: [BUTTON_PLAY_LIB_CARD, ...this.props.library],
            addingItem: false,
            loading: false
        });
    }


    async hydrateCardIds(cardIds) {
        const { library } = this.state;
        for (const cardId of cardIds) {
            const libCard = library.find(c => c.cardId === cardId);
            if (libCard && libCard.card) {
                await this.hydrateCard(libCard.card);
            }
        }
        this.setState({ library });
    }

    async hydrateCard(card) {
        if (!card) return null;
        if (card.content && card.content.chapters) return card;
        if (!card.content) card.content = {};
        card.content.chapters = (await api.getCardDetails(card.cardId)).content.chapters;
        return card;
    }

    lastPlayedNumChange(lastPlayedNum) {
        this.setState({ lastPlayedNum }, () => this.save());
    }

    async selectCard(selectedCard) {
        if (!selectedCard || !this.state.selectedCard || selectedCard.cardId !== this.state.selectedCard.cardId) {
            this.setState({ selectedChapter: PLAY_ENTIRE_CARD });
        }
        console.log('selectCard', selectedCard);
        await this.hydrateCard(selectedCard);
        this.setState({ selectedCard });
    }

    async addEntry() {
        const { cardItems, selectedCard, selectedChapter, selectedPosition, previousPosition } = this.state;
        const entry = {};
        console.log(cardItems);
        entry.cmd = selectedChapter && selectedChapter.key ? 'track-play' : 'card-play'
        entry.params = { card: selectedCard.cardId };
        if (selectedChapter && selectedChapter.key) {
            entry.params.chapter = selectedChapter.key;
            entry.params.track = selectedChapter.tracks[0].key;
        }
        if (previousPosition !== null && previousPosition !== undefined)
            cardItems.splice(previousPosition, 1);
        console.log(cardItems);
        cardItems.splice(selectedPosition, 0, entry);
        console.log(cardItems);
        await this.hydrateCardIds([selectedCard.cardId])
        this.setState({ cardItems, selectedChapter: null, previousPosition: null, addingItem: false });
        this.save();
    }

    deleteEntry(index) {
        const { cardItems } = this.state;
        cardItems.splice(index, 1);
        this.setState({ cardItems });
        this.save();
    }

    save() {
        const { cardItems, lastPlayedNum } = this.state;
        this.props.onChange({
            content: [...cardItems, { cmd: 'play-last', params: { num: lastPlayedNum } }]
        });
    }

    openAddDialog() {
        const { cardItems } = this.state;
        this.setState({
            addingItem: true,
            selectedPosition: cardItems.length,
            selectedCard: null,
            selectedChapter: null
        });
    }

    async openEditDialog(index) {
        const { cardItems } = this.state;
        const item = cardItems[index];
        const selectedCard = this.state.library.find(c => c.cardId === item.params.card).card;
        let selectedChapter = null;
        if (item.cmd === 'track-play') {
            await this.hydrateCard(selectedCard);
            selectedChapter = selectedCard.content.chapters.find(ch => ch.key === item.params.chapter);
        } else {
            selectedChapter = PLAY_ENTIRE_CARD;
        }
        this.setState({
            addingItem: true,
            previousPosition: index,
            selectedPosition: index,
            selectedCard,
            selectedChapter
        });
    }

    closeAddDialog() {
        this.setState({ addingItem: false, selectedPosition: null, previousPosition: null });
    }

    getCardTitle(cardId) {
        const libCard = this.state.library.find(c => (c.cardId === cardId))
        return  libCard ? libCard.card.title : 'Invalid card'
    }

    getTrackTitle(cardId, chapterKey, trackKey) {
        const libCard = this.state.library.find(c => (c.cardId === cardId));
        if (!libCard || !chapterKey || !trackKey) return '';
        const chapter = (libCard.card.content.chapters || []).find(ch => ch.key === chapterKey);
        if (!chapter) return 'Invalid track';
        const track = chapter.tracks.find(t => t.key === trackKey);
        if (!track) return 'Invalid track';
        return track.title;
    }

    render() {

        const { cardItems, library, selectedCard, selectedChapter, selectedPosition, mode, lastPlayedNum, loading } = this.state;
        return (
                         <div style={{ maxWidth: '550px' }}>
                            <div>
                                <h3 style={{paddingLeft: '20px'}}>{mode.toUpperCase()}</h3>
                                {   loading ? 'Loading...' :
                                    <><List className={'rhbEntryList'}>
                                    {cardItems.map((item, index) => (

                                    <ListItem className={'rhbEntryListItem'} key={`rhn-list-${mode}-${index}`}>
                                       <div className={ item.cmd === 'track-play' ? 'circle' : 'circle-full'}>
                                          <div className={ item.cmd === 'track-play' ? 'circle-text' : 'circle-full-text'}>{index + 1}</div>
                                       </div>
                                        <div className='rhbEntryTitle'>{
                                            this.getCardTitle(item.params.card)
                                        }
                                            <div style={{color:'#777'}}>{
                                                this.getTrackTitle(item.params.card, item.params.chapter, item.params.track)
                                            }</div>
                                        </div>
                                        <div style={{textAlign:'right', width: '100px'}}>
                                        <IconButton size={'small'} aria-label="edit" onClick={()=>{this.openEditDialog(index)}}>
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton size={'small'}  aria-label="delete" onClick={()=>{this.deleteEntry(index)}}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                        </div>
                                    </ListItem>


                                    ))
                                      }

                                    <ListItem className={'rhbEntryListItem'} >
                                        <div className='circle-full'>
                                            <div className= 'circle-full-text'><HistoryIcon fontSize={'small'}/></div>
                                        </div>
                                        <div className='rhbEntryTitle'>Shortcuts to last played cards
                                        </div>
                                        <Select
                                            value={lastPlayedNum || 0}
                                            onChange={(e) => {
                                                    this.lastPlayedNumChange( e.target.value)
                                                }
                                            }
                                            style={{ width: '100px', marginLeft: 'auto' }}
                                        >
                                            <MenuItem key={`selected-play-last-0`} value={0}>
                                                <ListItemText primary='Off' />
                                            </MenuItem>
                                            {[...Array(16).keys()].map((pos) => (
                                                <MenuItem key={`selected-play-last-${pos+1}`} value={pos+1}>
                                                    <ListItemText primary={pos + 1} />
                                                </MenuItem>
                                            )) }
                                        </Select>

                                    </ListItem>
                                <ListItem>
                                    <Button variant="contained" color="secondary" onClick={() => this.openAddDialog()}>Add shortcut</Button>
                                </ListItem>
                                </List>
                                </>}

                                <Dialog fullScreen open={this.state.addingItem || false} onClose={() => this.closeAddDialog()} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">Add item</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Add new shortcut to right hand button
                                        </DialogContentText>
                                        <div>
                                            <span>Position:</span>
                                        <FormControl
                                                     style={{ width: 120, marginBottom: '24px' }}

                                        >
                                            <Select
                                                id="position-select"
                                                label={"Position"}
                                                value={selectedPosition}
                                                onChange={(event) => {
                                                    this.setState({ selectedPosition: event.target.value });

                                                }}
                                            >


                                                {cardItems ? [...Array(cardItems.length + 1).keys()].map((pos) => (
                                                    <MenuItem key={`selected-chapter-${pos}`} value={pos}>
                                                        <div className='circle-full'>
                                                            <div className= 'circle-full-text'>{pos + 1}</div>
                                                        </div>
                                                    </MenuItem>
                                                )) : null}
                                            </Select>
                                        </FormControl>
                                        </div>
                                        <div>
                                            <span>Card</span>
                                            <FormControl
                                                style={{ width: 300, marginBottom: '24px' }}
                                                variant="outlined"  >
                                                <Autocomplete
                                            id="card-select"
                                            options={library.map((libCard) => libCard.card)}
                                            autoSelect
                                            value={selectedCard || ''}
                                            getOptionLabel={(option) => option.title}
                                            onChange={(event, newValue) => {
                                                this.selectCard(newValue);
                                            }}
                                             renderInput={(params) => <TextField {...params}  variant="outlined" />}
                                        />
                                            </FormControl>
                                        </div>
                                        <span>Chapter/track</span>
                                        <FormControl variant="outlined"
                                                     style={{ width: 300, marginBottom: '24px' }}>
                                            <Select
                                            disabled={!selectedCard}
                                            id="chapter-select"
                                            value={selectedChapter}
                                            onChange={(event) => {
                                                this.setState({ selectedChapter: event.target.value });
                                            }}

                                        >
                                            <MenuItem key={`selected-full-card`} value={PLAY_ENTIRE_CARD}>
                                                <ListItemText><i>Play entire card</i></ListItemText>
                                            </MenuItem>
                                            {selectedCard ? selectedCard.content.chapters.map((chapter, index) => (
                                                <MenuItem key={`selected-chapter-${index}`} value={chapter}>
                                                    <ListItemText primary={chapter.tracks[0].title} />
                                                </MenuItem>
                                            )) : null}
                                        </Select>
                                        </FormControl>

                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => this.closeAddDialog()} color="primary">
                                            Cancel
                                        </Button>
                                        <Button disabled={!this.state.selectedCard}  onClick={() => {
                                            this.addEntry('day');
                                            this.closeAddDialog();
                                        }} color="primary">
                                            Save
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                            </div>
                         </div>


        );
    }
}

DeviceBetaRhbModeConfig.propTypes = {
    config: PropTypes.object,
    mode: PropTypes.string,
    library: PropTypes.array,
    params: PropTypes.object,
    i18n: PropTypes.object,
    onChange: PropTypes.func
};

export default  withTranslation()(DeviceBetaRhbModeConfig);
